module.exports = {
  messages: {
    ADVERTISING_DIGITAL_SERVICE_ACT_HEADLINE: 'Why am I seeing this ad?',
    ADVERTISING_DIGITAL_SERVICE_ACT_POP_UP_FOOTER_CTA: 'Learn more',
    ADVERTISING_DIGITAL_SERVICE_ACT_POP_UP_FOOTER_MESSAGE:
      'about targeting criteria.',
    ADVERTISING_DIGITAL_SERVICE_ACT_POP_UP_MESSAGE:
      "You're seeing this ad because you meet at least one of the advertiser's targeting criteria.",
    ADVERTISING_LEAD_AD_FORM_CHECKBOX_ERROR: 'Please accept to proceed.',
    ASK_XING_NEW: 'New',
    ASK_XING_TITLE: 'Ask XING',
    BOTTOM_BAR_FIND_JOBS_LABEL: 'Find jobs',
    BOTTOM_BAR_INSIGHTS_LABEL: 'Insights',
    BOTTOM_BAR_NETWORK_LABEL: 'Network',
    BOTTOM_BAR_NOTIFICATIONS_LABEL: 'Notifications',
    BOTTOM_BAR_YOUR_JOBS_LABEL: 'Your jobs',
    BUTTON_CLOSEWINDOW: 'Close window',
    COMPANY_DIRECTORY_LIST_H: 'Companies starting with the letter',
    COMPANY_DIRECTORY_OTHERS: 'More',
    DISCIPLINE_1001: 'Analysis and statistics',
    DISCIPLINE_1002: 'Administration',
    DISCIPLINE_1003: 'Consulting',
    DISCIPLINE_1004: 'Customer service',
    DISCIPLINE_1005: 'Purchasing, materials management & logistics',
    DISCIPLINE_1006: 'Finance, accounting & controlling',
    DISCIPLINE_1007: 'Teaching, R&D',
    DISCIPLINE_1008: 'Health, medical and social',
    DISCIPLINE_1009: 'Graphic design and architecture',
    DISCIPLINE_1010: 'Engineering and technical',
    DISCIPLINE_1011: 'IT and software development',
    DISCIPLINE_1012: 'Management and corporate development',
    DISCIPLINE_1013: 'Marketing and advertising',
    DISCIPLINE_1014: 'HR',
    DISCIPLINE_1015: 'PR and journalism',
    DISCIPLINE_1016: 'Production and manufacturing',
    DISCIPLINE_1017: 'Product management',
    DISCIPLINE_1018: 'Project management',
    DISCIPLINE_1019: 'Process planning & QA',
    DISCIPLINE_1020: 'Legal',
    DISCIPLINE_1021: 'Sales and commerce',
    DISCIPLINE_1022: 'Other',
    'DISCO_OBJECTS_FOLLOWER.one': 'follower',
    'DISCO_OBJECTS_FOLLOWER.other': 'followers',
    'DISCO_OBJECTS_FOLLOWER.zero': 'followers',
    EMPLOYMENT_TYPE_CONTRACTOR: 'Self-employed',
    EMPLOYMENT_TYPE_CONTRACTOR_LOCAL_SERPS: 'Freelancer',
    EMPLOYMENT_TYPE_FULL_TIME: 'Full-time',
    EMPLOYMENT_TYPE_INTERN: 'Student',
    EMPLOYMENT_TYPE_PART_TIME: 'Part-time',
    EMPLOYMENT_TYPE_SEASONAL: 'Seasonal',
    EMPLOYMENT_TYPE_STUDENT_LOCAL_SERPS: 'Student jobs',
    EMPLOYMENT_TYPE_TEMPORARY: 'Temp',
    EMPLOYMENT_TYPE_TEMPORARY_LOCAL_SERPS: 'Mini',
    EMPLOYMENT_TYPE_VOLUNTARY: 'Volunteer',
    FRAME_HEADER_SEARCH: 'Search',
    JOB_SEARCH_BOOST_ME_UP_CTA: 'Jetzt Profil boosten',
    JOB_SEARCH_BOOST_ME_UP_HEADLINE: 'Booster für Dein Profil?',
    JOB_SEARCH_BOOST_ME_UP_PARAGRAPH:
      'Platziere Dein Profil genau da, wo’s sein soll: im Fokus passender Recruiter·innen.',
    JOB_SEARCH_BOOST_ME_UP_SENTENCE:
      'Hol Dir kostenlos eine 14-tägige Top-Platzierung!',
    JOB_SEARCH_PROJOBS_UPSELL_CTA: 'Upgrade now',
    JOB_SEARCH_PROJOBS_UPSELL_HEADLINE:
      'Let the jobs come to you with ProJobs!',
    JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_BASIC_MESSAGES_FACTOR: '10×',
    JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_BASIC_VISITS_FACTOR: '15×',
    JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_MESSAGES_FACTOR: '2×',
    JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_MESSAGES_TEXT: 'more messages',
    JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_VISITS_FACTOR: '2.5×',
    JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_VISITS_TEXT: 'more visits',
    JOB_SEO_CATEGORY_JOBS: '{category} Jobs',
    JOB_SEO_CITY_JOBS: 'Jobs in {city}',
    JOB_SEO_COMPANY_JOBS: '{term} jobs',
    JOB_SEO_EMPLOYMENT_TYPE_: 'Ehrenamt-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_CONTRACTOR: 'Freelancer-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_FULL_TIME: 'Vollzeit-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_INTERN: 'Studierende-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_PART_TIME: 'Teilzeit-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_SEASONAL: 'Saisonarbeit-Jobs',
    JOB_SEO_EMPLOYMENT_TYPE_TEMPORARY: 'Aushilfe-Jobs',
    JOB_SEO_JOB_TYPE_CONTRACTOR_JOBS: 'Find freelancer projects now.',
    JOB_SEO_JOB_TYPE_FULL_TIME_JOBS: 'Find your new full-time job now.',
    JOB_SEO_JOB_TYPE_INTERN_JOBS: 'Find the right internship now.',
    JOB_SEO_JOB_TYPE_PART_TIME_JOBS: 'Find your new part-time job now.',
    JOB_SEO_JOB_TYPE_SEASONAL_JOBS: 'Find your next seasonal job now.',
    JOB_SEO_JOB_TYPE_TEMPORARY_JOBS: 'Find your next temporary job now.',
    JOB_SEO_JOB_TYPE_VOLUNTARY_JOBS: 'Find your new voluntary work now.',
    JOB_SEO_SERPS_EMPTY_SEARCH: 'No results for {keyword}',
    JOB_SEO_TERM_JOBS: '{term} jobs',
    JOBS_CREATE_SEARCH_ALERT: 'Create search alert',
    JOBS_FIND_JOBS_SEARCH_BUTTON: 'Find jobs',
    JOBS_FIND_JOBS_SEARCH_LOCATION_PLACEHOLDER: 'Location',
    JOBS_FIND_JOBS_SEARCH_PLACEHOLDER: 'Enter a job title',
    JOBS_FIND_JOBS_SEARCH_RADIUS_PLACEHOLDER: 'Radius',
    JOBS_FIND_JOBS_TITLE: 'Find jobs',
    JOBS_JOB_TEASER_SAVE: 'Save job',
    JOBS_JOB_TEASER_SAVED: 'Saved',
    JOBS_MYJOBS_SEARCH_ALERTS_SECTION_TITLE: 'Search alerts',
    JOBS_RECENT_SEARCH_EMPTY: 'All jobs',
    JOBS_SEARCH_AI_BACK_TO_CLASSIC: 'Return to regular search',
    JOBS_SEARCH_AI_EMPTY_STATE_BANNER_SUBTITLE:
      'Give it another go with a different text. Or try removing a few filters.',
    JOBS_SEARCH_AI_ENTRYPOINT_BANNER: 'New: Find your next job with AI',
    JOBS_SEARCH_AI_ENTRYPOINT_FLAG_DESKTOP: 'Try now',
    JOBS_SEARCH_AI_ENTRYPOINT_FLAG_MOBILE: 'Try now',
    JOBS_SEARCH_AI_FEEDBACK_BAD_BUTTON: 'Take survey',
    JOBS_SEARCH_AI_FEEDBACK_BAD_COPY:
      'Not happy with your results? Let us know how we can improve our AI.',
    JOBS_SEARCH_AI_FEEDBACK_DISLIKE_A11Y_LABEL: 'Bad',
    JOBS_SEARCH_AI_FEEDBACK_HEADLINE: 'How would you rate these results?',
    JOBS_SEARCH_AI_FEEDBACK_LIKE_A11Y_LABEL: 'Good',
    JOBS_SEARCH_AI_FEEDBACK_NEUTRAL_A11Y_LABEL: 'Okay',
    JOBS_SEARCH_AI_FEEDBACK_THANKS:
      'Thanks for your feedback! Your input helps us improve.',
    JOBS_SEARCH_AI_FILTERS_SHOW_RESULTS_BUTTON: 'Show results',
    JOBS_SEARCH_AI_FLAG: 'AI-enhanced',
    JOBS_SEARCH_AI_HEADLINE: 'Welcome to a new breed of job search',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_1: 'Describe your ideal job…',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_10: 'Include your skills and experience',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_2:
      'Enter a description in full sentences…',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_3: 'Outline the jobs you’d like to do…',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_4: 'List ideas for your next career step…',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_5:
      'Job title, career level, salary expectations',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_6: 'Everything that’s important to you',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_7:
      'The more specific you can be, the better',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_8:
      'Part-time or full-time, work from home, in the office, or both',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_9: 'Avoid using just keywords',
    JOBS_SEARCH_AI_LOADING_SUGGESTION: 'Analysing your profile...',
    JOBS_SEARCH_AI_NO_RESULTS_HEADLINE: 'No matching jobs for you',
    JOBS_SEARCH_AI_PLACEHOLDER_1: 'Describe your dream job. Go wild!',
    JOBS_SEARCH_AI_PLACEHOLDER_2:
      'Every bit of detail helps improve your results',
    JOBS_SEARCH_AI_PLACEHOLDER_3:
      'Add your preferred job title, level, industry, etc.',
    JOBS_SEARCH_AI_PLACEHOLDER_4:
      'Full-time or part-time? Remote or in an office?',
    JOBS_SEARCH_AI_PLACEHOLDER_5: 'Include your skills and strengths',
    JOBS_SEARCH_AI_PLACEHOLDER_6: 'And how much you want to earn',
    JOBS_SEARCH_AI_PLACEHOLDER_7:
      "Play around with your description until you're happy with it",
    JOBS_SEARCH_AI_RESULTS_HEADLINE: 'Jobs matching your search',
    JOBS_SEARCH_AI_SUGGESTION_ERROR:
      'Try updating your profile and job preferences for a more detailed search with XING AI. Or simply enter what you’re looking for.',
    JOBS_SEARCH_AI_SUGGESTION_SERVER_ERROR:
      'That didn’t work. Please try again.',
    JOBS_SEARCH_AI_SWIMP_SUGGESTION: 'Search with my profile',
    JOBS_SEARCH_AI_SWIMP_SUGGESTION_SUBTITLE:
      'Discover your next step with XING AI',
    JOBS_SEARCH_AI_SWIMS_SUGGESTION: 'I want to change careers',
    JOBS_SEARCH_AI_SWIMS_SUGGESTION_SUBTITLE:
      'Browse entirely new career paths based on your skills thanks to XING AI',
    JOBS_SEARCH_ALERT_CREATED: 'Search alert created',
    JOBS_SEARCH_BUTTON_BACK: 'Back',
    JOBS_SEARCH_COMPANY_CULTURE_FILTER_VALUE: 'Culture match',
    JOBS_SEARCH_EDIT_ALERT_SAVE_BUTTON: 'Save changes',
    JOBS_SEARCH_EMPTY_ERROR_HEADLINE: 'Please enter a job title or location',
    JOBS_SEARCH_EMPTY_ERROR_TEXT:
      'Matches will be shown based on your entries.',
    JOBS_SEARCH_EMPTY_STATE_BANNER_SEARCH_ALERT_EDIT_SUBTITLE:
      'Try adjusting your filters, e.g. to include a larger radius, or using different keywords. Alternatively, simply wait for some results to show up here.',
    JOBS_SEARCH_EMPTY_STATE_BANNER_SEARCH_ALERT_SUBTITLE:
      'Try changing your search filters, e.g. by using a bigger radius or different keywords.',
    JOBS_SEARCH_EMPTY_STATE_BANNER_SUBTITLE:
      'Try changing your search filters, e.g. by using a bigger radius or different keywords, or create a search alert.',
    JOBS_SEARCH_EMPTY_STATE_NO_RESULTS_FOR_LABEL: 'for',
    JOBS_SEARCH_EMPTY_STATE_NO_RESULTS_TITLE: 'No results found',
    JOBS_SEARCH_EMPTY_STATE_RECOMMENDATIONS_HEADLINE:
      'Jobs you may also be interested in:',
    'JOBS_SEARCH_EMPTY_STATE_WIDER_RESULTS_HEADER.one':
      'Check out the job we found elsewhere:',
    'JOBS_SEARCH_EMPTY_STATE_WIDER_RESULTS_HEADER.other':
      'Check out the {variable_2} jobs we found elsewhere:',
    JOBS_SEARCH_FILTER_CAREER_LEVEL: 'Career level',
    JOBS_SEARCH_FILTER_CITY: 'City',
    JOBS_SEARCH_FILTER_COUNTRY: 'Country',
    JOBS_SEARCH_FILTER_DISCIPLINE: 'Discipline',
    JOBS_SEARCH_FILTER_EMPLOYEE_PERKS: 'Employee perks',
    JOBS_SEARCH_FILTER_EMPLOYMENT_TYPE: 'Employment type',
    JOBS_SEARCH_FILTER_INDUSTRY: 'Industry',
    JOBS_SEARCH_FILTER_LOCATION_TEXT: 'Location:',
    JOBS_SEARCH_FILTER_RADIUS: 'Search radius',
    JOBS_SEARCH_FILTER_RADIUS_SLIDER_TEXT_WEB: 'Only in {location}',
    JOBS_SEARCH_FILTER_REMOTE_OPTION: 'Workplace',
    JOBS_SEARCH_FILTER_SALARY: 'Salary',
    JOBS_SEARCH_FILTER_SALARY_MAX_SLIDER_A11Y: 'Maximum salary',
    JOBS_SEARCH_FILTER_SALARY_MIN_SLIDER_A11Y: 'Minimum salary',
    JOBS_SEARCH_FILTER_WORKING_ATMOSPHERE: 'Workplace culture',
    JOBS_SEARCH_FILTERS_ALL_HEADER: 'Filters',
    JOBS_SEARCH_FILTERS_SHOW_ALL: 'Show all',
    JOBS_SEARCH_FILTERS_SHOW_LESS: 'Show less',
    JOBS_SEARCH_FILTERS_SHOW_MORE: 'Show more',
    'JOBS_SEARCH_FILTERS_SHOW_RESULTS_BUTTON.one': 'Show 1 job',
    'JOBS_SEARCH_FILTERS_SHOW_RESULTS_BUTTON.other': 'Show {variable_2} jobs',
    'JOBS_SEARCH_FILTERS_SHOW_RESULTS_BUTTON.zero': '0 jobs',
    'JOBS_SEARCH_HEADLINE.one': '{variable_2} job found',
    'JOBS_SEARCH_HEADLINE.other': '{variable_2} jobs found',
    'JOBS_SEARCH_HEADLINE.zero': 'No results found',
    JOBS_SEARCH_LOCATION_NO_RADIUS: 'No radius',
    JOBS_SEARCH_PAGINATION_LOAD_MORE: 'Show more',
    JOBS_SEARCH_PAGINATION_TEXT: '{results} of {total} results',
    JOBS_SEARCH_RESET_FILTERS_BUTTON: 'Reset filters',
    JOBS_SEARCH_SORT_ORDER_NEWEST: 'Newest first',
    JOBS_SEARCH_SORT_ORDER_RELEVANCE: 'Most relevant first',
    JOBS_SEARCH_SORT_ORDER_TOP_RATED: 'Top-rated first',
    JOBS_SEARCH_SORT_TITLE: 'Sort',
    MEMENU_FOLLOWING_LABEL: 'Following',
    MEMENU_HELP_LABEL: 'Help',
    MEMENU_JOB_ADS_LABEL: 'Manage job ads',
    MEMENU_JOB_PREFERENCES_LABEL: 'Job preferences',
    MEMENU_MY_POSTS_LABEL: 'Your posts',
    MEMENU_PREMIUM_FOR_BASIC_USER_LABEL: 'Go Premium',
    MEMENU_PREMIUM_LABEL: 'Premium',
    MEMENU_PROBUSINESS_LABEL: 'ProBusiness',
    MEMENU_PROFILE_LABEL: 'Profile',
    MEMENU_PROJOBS_FOR_BASIC_USER_LABEL: 'Go ProJobs',
    MEMENU_PROJOBS_LABEL: 'ProJobs',
    MEMENU_SETTINGS_LABEL: 'Settings',
    MP_000_RESOURCE_JOB_TYPES_TEMPORARY: 'Temporary',
    NATIVE_ADS_DOT_MENU_REPORT_OBJECT: 'Report this ad',
    NATIVE_ADS_LEARN_MORE_BUTTON: 'Learn more',
    NATIVE_ADS_SPONSORED_LABEL: 'Sponsored',
    SEARCH_DROPDOWN_COMPANIES: 'Companies',
    SEARCH_DROPDOWN_JOBS: 'Jobs',
    SEARCH_DROPDOWN_MEMBERS: 'Members',
    SEARCH_DROPDOWN_NEWS: 'News',
    VERTICAL_NAV_BUSINESS_SOLUTIONS_LABEL: 'Business solutions',
    VERTICAL_NAV_BUSINESS_SOLUTIONS_SHORT_LABEL: 'Business',
    VERTICAL_NAV_FIND_JOBS_LABEL: 'Find jobs',
    VERTICAL_NAV_FOLLOWING_LABEL: 'Following',
    VERTICAL_NAV_INSIGHTS_LABEL: 'Insights',
    VERTICAL_NAV_JOB_PREFERENCES_LABEL: 'Job preferences',
    VERTICAL_NAV_MESSAGES_LABEL: 'Messages',
    VERTICAL_NAV_MY_POSTS_LABEL: 'Your posts',
    VERTICAL_NAV_NETWORK_LABEL: 'Network',
    VERTICAL_NAV_NETWORK_SHORT_LABEL: 'Network',
    VERTICAL_NAV_PREMIUM_FOR_BASIC_USER_LABEL: 'Go Premium',
    VERTICAL_NAV_PREMIUM_LABEL: 'Premium',
    VERTICAL_NAV_PREMIUM_SHORT_LABEL: 'Premium',
    VERTICAL_NAV_PROBUSINESS_LABEL: 'ProBusiness',
    VERTICAL_NAV_PROFILE_LABEL: 'Profile',
    VERTICAL_NAV_PROJOBS_FOR_BASIC_USER_LABEL: 'Go ProJobs',
    VERTICAL_NAV_PROJOBS_LABEL: 'ProJobs',
    VERTICAL_NAV_YOUR_JOBS_LABEL: 'Your jobs',
    XAM_GENERIC_ERROR_MESSAGE: 'Sorry, something went wrong. Please try again.',
    XAM_LEAD_AD_CUSTOM_QUESTION_PLACEHOLDER: 'Please enter your reply here.',
    XAM_LEAD_AD_FORM_THANKS_CTA_DOWNLOAD_NOW: 'Download now',
    XAM_LEAD_AD_FORM_THANKS_CTA_TRY_NOW: 'Try now',
    XAM_LEAD_AD_FORM_THANKS_CTA_VISIT_COMPANY_WEBSITE: 'Visit company website',
    XAM_LEAD_AD_FORM_THANKS_CTA_VISIT_NOW: 'Visit now',
    XAM_LEAD_AD_FORM_THANKS_CTA_VISIT_XING_PAGE: 'Visit XING page',
    XAM_LEAD_AD_PREVIEW_CONFIRMATION_SUCCESS_MESSAGE: 'Request sent',
    XAS_ARMSTRONG_LEAD_AD_FORM_THANKS_BUTTON_COPY: 'View XING Page',
    XDS_BACK_TO_TOP_BUTTON: 'Back to top',
    XDS_DISCOVER_BADGE_A11Y: 'New feature',
    XDS_EMPTYSTATE_ERROR_BUTTON: 'Try again',
    XDS_EMPTYSTATE_ERROR_DESCRIPTION: 'Please try again.',
    XDS_EMPTYSTATE_ERROR_TITLE: "That didn't work.",
    XDS_MINI_BADGE_A11Y: 'New notification',
    'XDS_NOTIFICATION_BADGE_GENERIC_A11Y.one': '1 new notification',
    'XDS_NOTIFICATION_BADGE_GENERIC_A11Y.other': '{value} new notifications',
    XDS_SKELETON_GENERIC_A11Y: 'Loading',
    XJM_BREADCRUMB_HOME: 'Jobs',
    XJM_JOBS_SEO_ROLE_FAQ_HEADLINE: 'Frequently asked questions',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_CITY_ANSWER:
      'The most popular city for {jobRole} is {firstCity}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_CITY_QUESTION:
      'Where are the most openings for the role of {jobRole}?',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_SKILL_ANSWER:
      '{firstSkill} is especially good to have for the role of {jobRole}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_SKILL_QUESTION:
      'What skills are needed for {jobRole}?',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_THREE_CITIES_ANSWER:
      'The most popular cities for {jobRole} are {firstCity}, {secondCity}, and {thirdCity}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_THREE_SKILLS_ANSWER:
      '{firstSkill}, {secondSkill} and {thirdSkill} are especially good to have for the role of {jobRole}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_TWO_CITIES_ANSWER:
      'The most popular cities for {jobRole} are {firstCity} and {secondCity}.',
    XJM_JOBS_SEO_ROLE_FAQ_TOP_TWO_SKILLS_ANSWER:
      '{firstSkill} and {secondSkill} are especially good to have for the role of {jobRole}.',
    XJM_JOBS_SEO_ROLE_RELATED_JOBS_HEADLINE: 'Related jobs',
    XJM_JOBS_SEO_ROLE_SALARY_DESCRIPTION:
      'The majority of people in the role of {jobRole} in Germany earn between {minimumSalary} and {maximumSalary}, with the average being €{averageSalary}.',
    XJM_JOBS_SEO_ROLE_SALARY_HEADER:
      'How much do you earn in the role of {jobRole}?',
    XJM_JOBS_SEO_ROLE_SALARY_LABEL: 'Average annual salary',
    XJM_META_DESCRIPTION_CATEGORY:
      "{category} vacancies: Find jobs at top {category} employers and interesting articles about tomorrow's working world.",
    XJM_META_DESCRIPTION_CATEGORY_CITY:
      "Find great {category} vacancies in {city} - Employers with reviews, interesting articles about tomorrow's working world and valuable contacts at your dream company...",
    XJM_META_DESCRIPTION_CATEGORY_TERM:
      "Find great {term} jobs in {category} - Employers with reviews, interesting articles about tomorrow's working world and valuable contacts at your dream company",
    XJM_META_DESCRIPTION_CITY_WITH_POSTINGS:
      '{postings} jobs in {city} on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_COMPANY:
      '{term} jobs on the XING jobs network ✓ Browse salary ranges ✓ Find your dream job now on XING!',
    XJM_META_DESCRIPTION_EMPLOYMENT_TYPE_CITY_WITH_POSTINGS:
      '{postings} {employmentType} jobs in {city} on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_JOB_TYPE:
      "Find {job_type} vacancies: Current job offers, top companies and interesting articles about tomorrow's working world.",
    XJM_META_DESCRIPTION_JOB_TYPE_CATEGORY:
      "Find {category} {job_type} vacancies - Employers with reviews, interesting articles about tomorrow's working world and valuable contacts at your dream company...",
    XJM_META_DESCRIPTION_JOB_TYPE_TERM:
      "Find {term} {job_type} vacancies - Employers with reviews,interesting articles about tomorrow's working world and valuable contacts at your dream company...",
    XJM_META_DESCRIPTION_JOB_TYPE_TERM_MINIJOBS:
      '✔ {term}-Stellenangebote (Mini-Jobs) ✔ Die beliebtesten Arbeitgeber ✔ Wissenswertes zur Arbeitswelt von heute',
    XJM_META_DESCRIPTION_SKILL:
      ' {postings} {term} jobs on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_SKILL_TYPE_CITY:
      '{postings} {term} jobs in {city} on the XING jobs network.',
    XJM_META_DESCRIPTION_TERM_ANY_WITH_POSTINGS:
      '{postings} {term} jobs on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_TERM_CITY_WITH_POSTINGS:
      '{postings} {role} jobs in {city} on the XING jobs network. Find just the right job for you.',
    XJM_META_DESCRIPTION_TERM_WITH_POSTINGS:
      '{postings} {role} jobs on the XING jobs network. Find just the right job for you.',
    XJM_META_TITLE_CATEGORY: 'Find {category} vacancies in XING Jobs!',
    XJM_META_TITLE_CATEGORY_CITY:
      'Find {category} vacancies {city} in XING Jobs!',
    XJM_META_TITLE_CATEGORY_TERM: 'Find {term} jobs in {category}',
    XJM_META_TITLE_CITY_WITH_DATE:
      'Current jobs & vacancies in {city} - {month} {year}',
    XJM_META_TITLE_COMPANY: '{term} jobs | XING jobs network',
    XJM_META_TITLE_EMPLOYMENT_TYPE_CITY_WITH_DATE:
      '{employmentType} jobs in {city} - {month} {year}',
    XJM_META_TITLE_JOB_TYPE: 'Find great {job_type} vacancies in XING Jobs!',
    XJM_META_TITLE_JOB_TYPE_CATEGORY:
      'Find {category} {job_type} vacancies in XING Jobs!',
    XJM_META_TITLE_JOB_TYPE_TERM:
      'Find {term} {job_type} vacancies in XING Jobs!',
    XJM_META_TITLE_JOB_TYPE_TERM_MINIJOBS:
      '{term} Mini-Jobs | XING: Jobs, die passen.',
    XJM_META_TITLE_SKILL: 'Current {term} jobs - {date}',
    XJM_META_TITLE_SKILL_TYPE_CITY:
      'Current {term} jobs in {city} - {month} {year}',
    XJM_META_TITLE_TERM_ANY_WITH_DATE: 'Current {term} jobs - {month} {year}',
    XJM_META_TITLE_TERM_CITY_WITH_POSTINGS:
      '{role} jobs in {city} - {month} {year}',
    XJM_META_TITLE_TERM_WITH_DATE: '{role} jobs & vacancies - {month} {year}',
    XJM_SEARCH_TITLE_CATEGORY_CITY: '{category} vacancies in {city}',
    XJM_SEARCH_TITLE_CATEGORY_TERM: 'Find {term} jobs in {category}',
    XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY: '{employmentType} jobs in {city}',
    XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY_FREELANCER:
      'Freelancer Jobs in {city}',
    XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY_STUDENTEN: 'Studentenjobs in {city}',
    XJM_SEARCH_TITLE_JOB_TYPE_CATEGORY: 'Find {category} {job_type} vacancies',
    XJM_SEARCH_TITLE_JOB_TYPE_CATEGORY_MINIJOBS: '{category} Minijobs',
    XJM_SEARCH_TITLE_JOB_TYPE_TERM: '{job_type} {term} vacancies',
    XJM_SEARCH_TITLE_SKILL_TYPE_CITY: '{term} jobs in {city}',
    XJM_SEARCH_TITLE_TERM_CITY_WITH_DATE: '{role} jobs in {city}',
    XJM_SEO_DIRECTORY_PAGINATION_H: 'Search jobs by first letter',
    XJM_SEO_JOB_ROLE: '{term} jobs',
    XJM_SEO_RADIUS: 'and within a radius of {radius} km',
    XJM_SEO_RELATED_JOB_TITLES_BY_SKILL: 'Other {skill} jobs',
    XJM_SEO_RELATED_SKILLS_BY_JOB_ROLE: 'Popular job searches',
    XJM_SEO_TOP_CITIES_NEARBY_TITLE: 'Nearby cities',
    XJM_SEO_TOP_CITIES_TITLE: 'Top cities',
    XJM_SEO_TOP_JOB_ROLE_LOCATIONS: 'Cities offering {role} jobs',
    XJM_SEO_TOP_JOBS_TITLE: 'Top jobs',
    XJM_SEO_TOP_JOBS_TYPES_TITLE: 'Job types',
    XJM_SEO_TOP_LOCATIONS_BY_SKILL: 'Cities offering {skill} jobs',
    XJM_SEO_XING_SERVICES_TITLE: 'XING Services',
    XJM_TEASER_LINKS_JOB_TYPE_TITLE: 'Jobs by employment type',
    XJM_TEASER_LINKS_JOBS: ' jobs',
    XJM_TEASER_LINKS_JOBS_IN: ' <strong>jobs</strong> in',
    XJM_TEASER_LINKS_TERM_TITLE: 'Most sought-after job titles',
  },
};
